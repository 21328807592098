import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import { Menubar } from 'primereact/menubar';
import ToolManagement from "./tool/ToolManagement";


//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import DailyManagement from "./daily/DailyManagement";
import TopicManagement from "./topic/TopicManagement";
import ProfessionManagement from "./profession/ProfessionManagement";


function App() {
    const isLoggedIn = !!localStorage.getItem('access_token');

    const items = [
        {
            label: '工具管理',
            icon: 'pi pi-fw pi-cog',
            command: () => window.location.replace('/tools')
        },
        {
            label: '专题管理',
            icon: 'pi pi-fw pi-file',
            command: () => window.location.replace('/topics')
        },
        {
            label: '日报管理',
            icon: 'pi pi-fw pi-calendar',
            command: () => window.location.replace('/dailies')
        },
        {
            label: '职业管理',
            icon: 'pi pi-fw pi-briefcase',
            command: () => window.location.replace('/professions')
        }
    ];

    return (
        <div>
            <Menubar model={items} />
            <Router>

                {/* 其他路由配置 */}
                <Routes>
                    <Route
                        exact
                        path="/"
                        render={() => (isLoggedIn ? <Navigate to="/" /> : <Navigate to="/login" />)}
                    />
                    <Route path="/login" element={<LoginPage/>} />
                    <Route path="/tools" element={<ToolManagement/>} />
                    <Route path="/dailies" element={<DailyManagement/>} />
                    <Route path="/topics" element={<TopicManagement/>} />
                    <Route path="/professions" element={<ProfessionManagement/>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
