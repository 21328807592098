import React, {useState, useEffect, useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import axiosInstance from '../axiosInstance';
import {Button} from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import withAuth from "../withAuth";
import FileUploader from "../FileUploader";
import {InputTextarea} from "primereact/inputtextarea";

const DailyManagement = () => {
    const [dailies, setDailies] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageOffset, setPageOffset] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [selectedDaily, setSelectedDaily] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);



    const fetchDailies = async (pageSize, pageOffset) => {
        try {
            const data = await axiosInstance.post('/api/daily/page', {
                page_size: pageSize,
                page_offset: pageOffset
            });

            setDailies(data.content);
            setTotalRecords(data.total);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        fetchDailies(pageSize, pageOffset);
    }, [pageSize, pageOffset]);

    const onPageChange = (event) => {
        console.log(event)
        setPageOffset(event.page);
        setPageSize(event.rows);
    };

    const editDaily = (daily) => {
        setSelectedDaily(daily);

        console.log("selected daily:", daily)

        setDisplayDialog(true);
        // 实现编辑工具的逻辑，例如跳转到编辑页面
        console.log('编辑日报:', daily);
    };

    const handleSave = async () => {

        try {
            // 调用更新日报接口
            console.log(selectedDaily);
            axiosInstance.patch(`/api/daily/${selectedDaily.id}`, selectedDaily).then(data => {
                // 关闭模态框并刷新工具列表
                setDisplayDialog(false);
                fetchDailies(pageSize, pageOffset);
            })
        } catch (error) {
            alert("更新日报失败！");
            // 处理请求错误
            console.error('更新日报失败:', error);
        }
    };


    const editButton = (rowData) => {
        return (
            <Button
                label="编辑"
                icon="pi pi-pencil"
                className="p-button-text"
                onClick={() => editDaily(rowData)}
            />
        );
    };

    const handleCoverImageUploadSuccess = (url) => {
        setSelectedDaily((prevSelectedDaily) => ({
            ...prevSelectedDaily,
            image_url: url
        }));
    };

    const handleContentImageUploadSuccess = (url) => {
        setSelectedDaily((prevSelectedDaily) => ({
            ...prevSelectedDaily,
            image_url: url
        }));
    };


    const renderDialog = () => {
        if (!selectedDaily) {
            return null;
        }

        return (
            <Dialog
                header="编辑日报"
                visible={displayDialog}
                style={{ width: '90vw', height: '80vw'}}
                onHide={() => setDisplayDialog(false)}
            >
                <div className="p-field">
                    <label htmlFor="title">标题：</label>
                    <InputText id="title" value={selectedDaily.title}
                               onChange={(e) => {setSelectedDaily({...selectedDaily, title: e.target.value})}}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="sub_title">副标题：</label>
                    <InputText id="sub_title" value={selectedDaily.sub_title}
                               onChange={(e) => {setSelectedDaily({...selectedDaily, sub_titile: e.target.value})}}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="create_date">创作日期：</label>
                    <InputText id="sub_title" value={selectedDaily.create_date}
                               onChange={(e) => {setSelectedDaily({...selectedDaily, create_date: e.target.value})}}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="summary">摘要：</label>
                    <InputTextarea autoResize cols={50}
                                   id="summary" value={selectedDaily.summary}
                                   onChange={(e) => {setSelectedDaily({...selectedDaily, summary: e.target.value})}}
                    />
                </div>

                <div className="p-field">
                    <label htmlFor="create_time">创建时间：</label>
                    <InputText id="create_time" value={selectedDaily.create_time} readOnly />
                </div>
                <div>
                    <div className="p-field">
                        <label htmlFor="icon">日报封面图：</label>
                        <img src={selectedDaily.image_url} alt="日报封面图" style={{maxWidth: '400px', maxHeight: '320px'}}/>
                    </div>
                    <FileUploader onUploadSuccess={handleCoverImageUploadSuccess} />
                </div>

                <div>
                    <div className="p-field">
                        <label htmlFor="icon">日报内容图：</label>
                        <img src={selectedDaily.content_image_url} alt="日报内容图" style={{maxWidth: '400px'}} />
                    </div>
                    <FileUploader onUploadSuccess={handleContentImageUploadSuccess} />
                </div>

                {/* 更多字段 */}
                <div className="p-field">
                    <Button label="保存" className="p-button-primary" onClick={handleSave}/>
                </div>
            </Dialog>
        );
    };


    return (
        <div>
            <DataTable value={dailies}>
                <Column header="操作" body={editButton}/>
                <Column field="title" header="标题"/>
                <Column field="sub_title" header="副标题" />
                <Column field="create_date" header="创作日期" />
                <Column field="create_time" header="创建时间" />
            </DataTable>
            <Paginator
                first={pageOffset * pageSize}
                rows={pageSize}
                totalRecords={totalRecords}
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={onPageChange}
            />
            {renderDialog()}
        </div>
    );
};


export default withAuth(DailyManagement);
