import React, { useState } from 'react';
import axiosInstance from './axiosInstance';
import { useNavigate } from 'react-router-dom';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const requestData = {
                username: username,
                password: password,
            };

            const response = await axiosInstance.post('/api/auth/login', requestData);
            const accessToken = response.access_token;

            // 将ACCESS_TOKEN存储到localStorage，以便后续请求使用
            localStorage.setItem('accessToken', accessToken);

            // 跳转到工具页面
            navigate('/tools', {replace: true});
        } catch (error) {
            console.error('Error during login:', error);
            // 显示错误消息
        }
    };

    return (
        <div className="login-page">
            <h1>Login</h1>
            <form onSubmit={handleLogin}>
                <div>
                    <label htmlFor="username">Username:</label>
                    <InputText
                        id="username"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="password">Password:</label>
                    <InputText
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <Button label="Login" type="submit" icon="pi pi-check" />
            </form>
        </div>
    );
};

export default LoginPage;
