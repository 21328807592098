import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.data.code === 1) {
            // 请求成功，返回data字段的内容
            return response.data.data;
        } else {
            // 请求失败，抛出错误
            const error = new Error(response.data.message || '请求失败');
            return Promise.reject(error);
        }
    },
    (error) => {
        // 处理网络错误或其他未知错误
        return Promise.reject(error);
    }
);

export default axiosInstance;
