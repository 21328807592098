import React, {useEffect, useRef, useState} from 'react';
import { FileUpload } from 'primereact/fileupload';
import COS from 'cos-js-sdk-v5';
import {InputText} from "primereact/inputtext";
import axiosInstance from "./axiosInstance";
import {Toast} from "primereact/toast";

const cosBucket = process.env.REACT_APP_COS_BUCKET;
const cosCustomUrlPrefix = process.env.REACT_APP_COS_CUSTOM_URL_PREFIX;
const FileUploader = ({ onUploadSuccess }) => {
    const [targetLocation, setTargetLocation] = useState('');
    const [tempKeys, setTempKeys] = useState(null);
    const toast = useRef(null);

    const fetchTempKeys = async () => {
        try {
            const data = await axiosInstance.get('/api/storage/cos/tempkey', {
                params: {
                    bucket: cosBucket,
                    region: 'ap-shanghai'
                }
            });

            setTempKeys(data);
        } catch (error) {
            console.error('获取临时密钥失败', error);
        }
    };

    useEffect(() => {
        fetchTempKeys();
    }, []);

    const handleFileUpload = async (e) => {
        // 检查临时密钥是否过期
        if (!tempKeys || Date.now() / 1000 > tempKeys.expired_time) {
            await fetchTempKeys();
        }

        const file = e.files[0];
        if (file && tempKeys) {
            // 使用临时密钥配置COS客户端
            const cos = new COS({
                getAuthorization: function (options, callback) {
                    callback({
                        TmpSecretId: tempKeys.secret_id,
                        TmpSecretKey: tempKeys.secret_key,
                        XCosSecurityToken: tempKeys.session_key,
                        startTime: tempKeys.start_time,
                        ExpiredTime: tempKeys.expired_time
                    });
                }
            });

            // 生成文件名，可以根据需要修改
            const fileName = `${targetLocation || file.name}`;

            cos.putObject(
                {
                    Bucket: cosBucket,
                    Region: 'ap-shanghai',
                    Key: fileName,
                    StorageClass: 'STANDARD',
                    Body: file
                },
                function (err, data) {
                    if (err) {
                        console.error('上传文件失败', err);
                        toast.current.show({ severity: 'error', summary: '错误', detail: '文件上传失败', life: 3000 });
                    } else {
                        toast.current.show({ severity: 'success', summary: 'Info', detail: '上传成功', life: 1000 });
                        console.log('上传文件成功', data);
                        // 调用外部传入的 onUploadSuccess 回调函数
                        if (typeof onUploadSuccess === 'function') {
                            onUploadSuccess(data.Location.replace(/^[^/]+/, cosCustomUrlPrefix));
                        }
                    }
                }
            );
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="p-inputgroup">
                <label htmlFor="targetLocation">目标路径：</label>
                <span className="p-inputgroup-addon">{cosCustomUrlPrefix}</span>
                <InputText
                    id="targetLocation"
                    value={targetLocation}
                    onChange={(e) => setTargetLocation(e.target.value)}
                />
            </div>
            <FileUpload
                mode="basic"
                accept="image/*"
                maxFileSize={1000000}
                customUpload={true}
                uploadHandler={handleFileUpload}
            />
        </div>
    );
};

export default FileUploader;
