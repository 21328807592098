import React from 'react';
import { Navigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
    return class extends React.Component {
        render() {
            const accessToken = localStorage.getItem('accessToken');

            if (!accessToken) {
                // 用户未登录，重定向到登录页面
                return <Navigate to="/login" />;
            }

            // 用户已登录，渲染原始组件
            return <WrappedComponent {...this.props} />;
        }
    };
};

export default withAuth;
