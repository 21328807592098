import React, {useState, useEffect, useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import axiosInstance from '../axiosInstance';
import {Button} from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import withAuth from "../withAuth";
import FileUploader from "../FileUploader";
import {InputTextarea} from "primereact/inputtextarea";

const ProfessionManagement = () => {
    const [profession, setProfession] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageOffset, setPageOffset] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [selectedProfession, setSelectedProfession] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);



    const fetchProfession = async (pageSize, pageOffset) => {
        try {
            const data = await axiosInstance.post('/api/profession/page', {
                page_size: pageSize,
                page_offset: pageOffset
            });

            setProfession(data.content);
            setTotalRecords(data.total);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        fetchProfession(pageSize, pageOffset);
    }, [pageSize, pageOffset]);

    const onPageChange = (event) => {
        console.log(event)
        setPageOffset(event.page);
        setPageSize(event.rows);
    };

    const editProfession = (Profession) => {
        setSelectedProfession(Profession);

        console.log("selected Profession:", Profession)

        setDisplayDialog(true);
        // 实现编辑工具的逻辑，例如跳转到编辑页面
        console.log('编辑职业:', Profession);
    };

    const handleSave = async () => {

        try {
            // 调用更新职业接口
            console.log(selectedProfession);
            axiosInstance.patch(`/api/profession/${selectedProfession.id}`, selectedProfession).then(data => {
                // 关闭模态框并刷新工具列表
                setDisplayDialog(false);
                fetchProfession(pageSize, pageOffset);
            })
        } catch (error) {
            alert("更新职业失败！");
            // 处理请求错误
            console.error('更新职业失败:', error);
        }
    };


    const editButton = (rowData) => {
        return (
            <Button
                label="编辑"
                icon="pi pi-pencil"
                className="p-button-text"
                onClick={() => editProfession(rowData)}
            />
        );
    };

    const handleImageUploadSuccess = (url) => {
        setSelectedProfession((prevSelectedProfession) => ({
            ...prevSelectedProfession,
            image_url: url,
        }));
    };


    const renderDialog = () => {
        if (!selectedProfession) {
            return null;
        }

        return (
            <Dialog
                header="编辑职业"
                visible={displayDialog}
                style={{ width: '90vw', height: '80vw'}}
                onHide={() => setDisplayDialog(false)}
            >
                <div className="p-field">
                    <label htmlFor="name">名称：</label>
                    <InputText id="name" value={selectedProfession.title}
                               onChange={(e) => {setSelectedProfession({...selectedProfession, title: e.target.value})}}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="description">描述：</label>
                    <InputTextarea autoResize cols={50}
                                   id="description" value={selectedProfession.description}
                                   onChange={(e) => {setSelectedProfession({...selectedProfession, description: e.target.value})}}
                    />
                </div>

                <div className="p-field">
                    <label htmlFor="create_time">创建时间：</label>
                    <InputText id="create_time" value={selectedProfession.create_time} readOnly />
                </div>
                <div>
                    <div className="p-field">
                        <label htmlFor="icon">职业图片：</label>
                        <img src={selectedProfession.image_url} alt="职业图片" style={{maxWidth: '400px', maxHeight: '320px'}}/>
                    </div>
                    <FileUploader onUploadSuccess={handleImageUploadSuccess} />
                </div>


                {/* 更多字段 */}
                <div className="p-field">
                    <Button label="保存" className="p-button-primary" onClick={handleSave}/>
                </div>
            </Dialog>
        );
    };


    return (
        <div>
            <DataTable value={profession}>
                <Column header="操作" body={editButton}/>
                <Column field="name" header="职业"/>
                <Column field="description" header="描述" />
                <Column field="create_time" header="创建时间" />
            </DataTable>
            <Paginator
                first={pageOffset * pageSize}
                rows={pageSize}
                totalRecords={totalRecords}
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={onPageChange}
            />
            {renderDialog()}
        </div>
    );
};


export default withAuth(ProfessionManagement);
