import React, {useState, useEffect, useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import axiosInstance from '../axiosInstance';
import {Button} from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import withAuth from "../withAuth";
import FileUploader from "../FileUploader";
import {InputTextarea} from "primereact/inputtextarea";

const ToolManagement = () => {
    const [tools, setTools] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageOffset, setPageOffset] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filters, setFilters] = useState({});
    const [selectedTool, setSelectedTool] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);



    const fetchTools = async (pageSize, pageOffset) => {
        try {
            const response = await axiosInstance.post('/api/tool/page', {
                page_size: pageSize,
                page_offset: pageOffset
            });

            setTools(response.content);
            setTotalRecords(response.total);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchFilters = async () => {
        try {
            const data = await axiosInstance.get('/api/tool/filters');
            setFilters(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchFilters();
    }, []);

    useEffect(() => {
        fetchTools(pageSize, pageOffset);
    }, [pageSize, pageOffset]);

    const onPageChange = (event) => {
        console.log(event)
        setPageOffset(event.page);
        setPageSize(event.rows);
    };

    const editTool = (tool) => {
        let newTool = {...tool,
            name: tool.name,
            selectedLanguageCodes: tool.languages.map(i=>i.code),
            systemEnvironmentCodes: tool.system_environments.map(i=>i.code),
            usefulnessCode: tool.usefulness.code,
            chargingPlanCodes: tool.charging_plans.map(p=>p.code),
            difficultyCode: tool.difficulty.code,
            accessModeCode: tool.access_mode.code,
            runningStatusCode: tool.running_status.code,
            publishStatusCode: tool.publish_status.code,
            iconName: "",
            iconUrl: "",
        }
        setSelectedTool(
            newTool
        );

        console.log("selected tool:", newTool)

        setDisplayDialog(true);
        // 实现编辑工具的逻辑，例如跳转到编辑页面
        console.log('编辑工具:', tool);
        console.log('Filters:', filters);
    };

    const handleSave = async () => {
        console.log("保存")
        // 创建请求数据对象
        const requestData = {
            name: selectedTool.name,
            icon: selectedTool.icon,
            image_url: selectedTool.image_url,
            positioning: selectedTool.positioning,
            system_environment_codes: selectedTool.systemEnvironmentCodes,
            tags: selectedTool.tags,
            usefulness_code: selectedTool.usefulnessCode,
            language_codes: selectedTool.selectedLanguageCodes,
            charging_plan_codes: selectedTool.chargingPlanCodes,
            difficulty_code: selectedTool.difficultyCode,
            access_mode_code: selectedTool.accessModeCode,
            url: selectedTool.url,
            highlights: selectedTool.highlights,
            summary: selectedTool.summary,
            running_status_code: selectedTool.runningStatusCode,
            publish_status_code: selectedTool.publishStatusCode,
        };

        try {
            // console.log("data: ", selectedTool)
            // 调用更新工具接口
            console.log(requestData);
            axiosInstance.patch(`/api/tool/${selectedTool.id}`, requestData).then(data => {
                // 关闭模态框并刷新工具列表
                setDisplayDialog(false);
                fetchTools(pageSize, pageOffset);
            })
        } catch (error) {
            alert("更新工具失败！");
            // 处理请求错误
            console.error('更新工具失败:', error);
        }
    };


    const editButton = (rowData) => {
        return (
            <Button
                label="编辑"
                icon="pi pi-pencil"
                className="p-button-text"
                onClick={() => editTool(rowData)}
            />
        );
    };

    const handleIconUploadSuccess = (url) => {
        setSelectedTool((prevSelectedTool) => ({
            ...prevSelectedTool,
            icon: {
                ...prevSelectedTool.icon,
                type: 'url',
                value: url
            }
        }));
    };


    const renderDialog = () => {
        if (!selectedTool) {
            return null;
        }



        return (
            <Dialog
                header="编辑工具"
                visible={displayDialog}
                style={{ width: '90vw', height: '80vw'}}
                onHide={() => setDisplayDialog(false)}
            >
                <div className="p-field">
                    <label htmlFor="name">名称：</label>
                    <InputText id="name" value={selectedTool.name}
                               onChange={(e) => {setSelectedTool({...selectedTool, name: e.target.value})}}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="positioning">定位：</label>
                    <InputText id="positioning" value={selectedTool.positioning}
                               onChange={(e) => {setSelectedTool({...selectedTool, positioning: e.target.value})}}
                    />
                </div>
                <div>
                    <div className="p-field">
                        <label htmlFor="icon">图标：</label>
                        {selectedTool.icon?.type === 'emoji' ? (
                            <span>{selectedTool.icon?.value}</span>
                        ) : (
                            <img src={selectedTool.icon?.value} alt="图标" style={{ width: '48px', height: '48px' }} />
                        )}
                    </div>
                    <FileUploader onUploadSuccess={handleIconUploadSuccess} />
                </div>


                <div className="p-field">
                    <label htmlFor="tags">相关标签：</label>
                    <MultiSelect
                        id="tags"
                        value={selectedTool.tags}
                        options={filters.tags.map((tag) => ({ value: tag }))}
                        onChange={(e) => {setSelectedTool({...selectedTool, tags: e.target.value})}}
                        optionLabel="value"
                        display="chip"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="highlights">产品亮点：</label>
                    <MultiSelect
                        id="highlights"
                        value={selectedTool.highlights}
                        options={filters.highlights.map((highlight) => ({ value: highlight }))}
                        onChange={(e) => {setSelectedTool({...selectedTool, highlights: e.target.value})}}
                        optionLabel="value"
                        display="chip"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="url">网址：</label>
                    <InputText id="url" value={selectedTool.url} size={50}
                               onChange={(e) => {setSelectedTool({...selectedTool, url: e.target.value})}}
                    />
                </div>
                {/* 更多字段 */}
                <div className="p-field">
                    <label htmlFor="languages">支持语言：</label>
                    <MultiSelect
                        id="languages"
                        value={selectedTool.selectedLanguageCodes}
                        onChange={(e) => {
                            setSelectedTool({...selectedTool, selectedLanguageCodes: e.target.value})
                        }}
                        options={filters.languages}
                        optionValue="code"
                        optionLabel="value"
                        display="chip"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="usefulness">实用程度：</label>
                    <Dropdown
                        id="usefulness"
                        value={selectedTool.usefulnessCode}
                        options={filters.usefulnesses}
                        onChange={(e) => {
                            setSelectedTool({ ...selectedTool, usefulnessCode: e.target.value});
                        }}
                        optionValue="code"
                        optionLabel="value"
                        display="chip"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="accessMode">访问方式：</label>
                    <Dropdown
                        id="accessMode"
                        value={selectedTool.accessModeCode}
                        options={filters.publish_statuses}
                        onChange={(e) => {
                            setSelectedTool({ ...selectedTool, accessModeCode: e.target.value});
                        }}
                        optionValue="code"
                        optionLabel="value"
                        display="chip"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="runningStatus">运行状态：</label>
                    <Dropdown
                        id="runningStatus"
                        value={selectedTool.runningStatusCode}
                        options={filters.publish_statuses}
                        onChange={(e) => {
                            setSelectedTool({ ...selectedTool, runningStatusCode: e.target.value});
                        }}
                        optionValue="code"
                        optionLabel="value"
                        display="chip"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="publishStatus">发布状态：</label>
                    <Dropdown
                        id="publishStatus"
                        value={selectedTool.publishStatusCode}
                        options={filters.publish_statuses}
                        onChange={(e) => {
                            setSelectedTool({ ...selectedTool, publishStatusCode: e.target.value});
                        }}
                        optionValue="code"
                        optionLabel="value"
                        display="chip"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="chargingPlans">收费计划：</label>
                    <MultiSelect
                        id="chargingPlans"
                        value={selectedTool.chargingPlanCodes}
                        options={filters.charging_plans}
                        onChange={(e) => {
                            setSelectedTool({ ...selectedTool, chargingPlanCodes: e.target.value });
                        }}
                        optionValue="code"
                        optionLabel="value"
                        display="chip"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="system_environments">系统环境：</label>
                    <MultiSelect
                        id="system_environments"
                        value={selectedTool.systemEnvironmentCodes}
                        options={filters.system_environments}
                        onChange={(e) => {
                            setSelectedTool({ ...selectedTool, systemEnvironmentsCodes: e.target.value });
                        }}
                        optionValue="code"
                        optionLabel="value"
                        display="chip"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="summary">推荐词：</label>
                    <InputTextarea autoResize cols={50}
                        id="summary" value={selectedTool.summary}
                        onChange={(e) => {setSelectedTool({...selectedTool, summary: e.target.value})}}
                    />
                </div>

                <div className="p-field">
                    <label htmlFor="create_time">创建时间：</label>
                    <InputText id="create_time" value={selectedTool.create_time} readOnly />
                </div>
                {/* 更多字段 */}
                <div className="p-field">
                    <Button label="保存" className="p-button-primary" onClick={handleSave}/>
                </div>
            </Dialog>
        );
    };


    return (
        <div>
            <DataTable value={tools}>
                <Column header="操作" body={editButton}/>
                <Column
                    field="icon.value"
                    header="图标"
                    body={(rowData) => <img src={rowData.icon?.value} alt={rowData.name} style={{ width: '50px' }} />}
                />
                <Column field="name" header="名称"/>
                <Column field="positioning" header="定位" />
                <Column
                    field="tags"
                    header="标签"
                    body={(rowData) => rowData.tags.join(', ')}
                />
                <Column
                    field="highlights"
                    header="产品亮点"
                    body={(rowData) => rowData.highlights.join(', ')}
                />
                <Column field="url" header="网址" />
                <Column field="create_time" header="创建时间" />
            </DataTable>
            <Paginator
                first={pageOffset * pageSize}
                rows={pageSize}
                totalRecords={totalRecords}
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={onPageChange}
            />
            {renderDialog()}
        </div>
    );
};


export default withAuth(ToolManagement);
